export default class Service {
  constructor() {}

  csrfToken() {
    const $csrfToken: JQuery = $('meta[name="csrf-token"]');
    if ($csrfToken.length) {
      return $csrfToken.attr('content');
    }
    return null
  }

  authorize(response) {
    if (response.status == 401) {
      window.location.pathname = '/';
    }
  }

}